import React from 'react'
import Layouts from '@react/layouts/Layouts.tsx'
import { useAstroQuery } from '@/helpers/apollo'
import gql from 'graphql-tag'


const Homepage = () => {
    // const {data, loading} = useAstroQuery(gql `query MyQuery {
    //     product {
    //       id
    //       ref
    //       slug
    //       title
    //       hero_img
    //       price
    //       content
    //     }
    //   }
    // `)

    return <Layouts>
        <h2>Nos réfrigérants</h2>
        <div className='layouts__container--pub'>
            <a href='#'>
                <img src="/img/autoproac.png" />
                Auto PRO AC
            </a>
            <a href='#'>
                <img src="/img/newac.png" />
                New AC
            </a>
            <a href='#'>
                <img src="/img/domestic.png" />
                Domestic
            </a>
            <a href='#'>
                <img src="/img/cooling.png" />
                Cooling
            </a>
            <a href='#'>
                <img src="/img/refrige.png" />
                Refrige
            </a>
            <a href='#'>
                <img src="/img/supercold.png" />
                Super Cold
            </a>
        </div>
        <h2>Réfrigérants naturels en vente libre à base d’hydrocarbures à haute performance</h2>
        <div className="layouts__container--hydrocarbures">
            <p>GreenFreeze® : fournisseur de réfrigérants écologiques "gaz de clim 100 % naturel"  substituants naturels, climatisation.</p>
            <p>
                Professionnel de la Climatisation depuis plus de 30 ans, nous vous fournissons une large gamme de réfrigérants naturels HydroCarbonés ainsi que leurs accessoires pour mieux répondre à vos applications en Climatisation, Chauffage par Pompe à chaleur ("Clim Réversible"), Géothermie, Réfrigération et Congélation jusqu'à moins quatre vingt degrés Celsius  - 80 °C . 
                <br/>
                Plus de 100 millions d'utilisateurs de par le monde.
                <br/>
                250 000 utilisateurs en France en 2021 sans le moindre problème.
            </p>
            <p>
                Pas besoin d'attestation de capacité, de certificat d'aptitude, de traçabilité, pour acheter ou manipuler les réfrigérants GreenFreeze® car ils sont écologiques donc non polluants : sans Fluor, sans Chlore (GWP-PRP: 5).
                <br/>
                Les réfrigérants GreenFreeze® procurent une qualité de refroidissement supérieure aux réfrigérants synthétiques traditionnels et sont sécuritaires et très efficaces.
            </p>
            <p>
                Hors France métropolitaine et pays limitrophes : nous contacter pour les frais d'envois.
                <br/>
                Pour chaque commande passée, merci de bien vouloir nous indiquer votre numéro de téléphone.
            </p>
        </div>
        <h2>Différents réfrigérants pour s'adapter à tous les besoins... et bien plus encore !</h2>
        <div className="layouts__container--info">
            <div className="layouts__container--info--blocks">
                <div className="layouts__container--info--blocks--block">
                    <h3>Efficace et économique</h3>
                    L'efficacité supérieure des fluides frigorigènes hydrocarbures GreenFreeze, combinée à un poids de charge réduit, permet de réaliser de belles économies de coûts d'installation et d'exploitation.

                    Des économies continues de carburant ou d'électricité pour l'utilisateur : économie minimale de 10%. Les applications typiques de climatisation ou de réfrigération ne nécessitent que peu ou pas de modifications du système.
                </div>
                <div className="layouts__container--info--blocks--block">
                    <h3>Excelle dans des conditions extrêmes</h3>
                    Les réfrigérants de GreenFreeze refroidissent superbement dans toutes les conditions, mais fonctionnent particulièrement bien dans des conditions de chaleur extrême ou tropicale. 

                    Dans ces conditions, la plupart des autres réfrigérants et particulièrement les réfrigérants synthétiques (CFC, HFC, HCFC) ne fonctionnent plus.
                </div>
                <div className="layouts__container--info--blocks--block">
                    <h3>Vert et naturel</h3>
                    Les fluides frigorifiques GreenFreeze sont basés sur des gaz d'hydrocarbures naturels, qui sont purifiés et mélangés avec précision à l'aide de procédés GreenFreeze uniques.

                    Les réfrigérants GreenFreeze n'ont pas d'impact sur l'ozone et ont un PRG de 3kg de CO2/ Kg de réfrigérant et donc, non soumis à déclaration. Ils sont sans fluor !     
                </div>
                <div className="layouts__container--info--blocks--block">
                    <h3>Remplace la plupart des réfrigérants synthétiques</h3>
                    Les réfrigérants hydrocarbonés GreenFreeze ont été adoptés comme solution de remplacement idéale pour le R134, le R404a, le R410a et le R32 dans la plupart des applications de climatisation, de réfrigération et de chauffage, aussi bien dans les systèmes existants que dans les nouvelles installations. 
                </div>
                <div className="layouts__container--info--blocks--block">
                    <h3>Utilise l'équipement existant</h3>
                    Les marques les plus populaires d'outils et d'équipements de climatisation, pompes à chaleur, réfrigération sont compatibles avec les réfrigérants GreenFreeze. Veuillez vous reporter aux spécifications du fabricant ou contacter GreenFreeze pour obtenir des conseils. 
                </div>
                <div className="layouts__container--info--blocks--block">
                    <h3>Conforme aux normes internationales</h3>
                    Les réfrigérants hydrocarbonés GreenFreeze sont conformes aux normes internationales, telles que : ISO 5149, la série ISO / IEC 60335, BS 4434-1995 ainsi que AS / NZS 1677, qui couvre également l'utilisation professionnelle des réfrigérants à base d'hydrocarbures.
                </div>
            </div>
        </div>
        {/* {
            data && data.product.map((p) => (
            <div className='product_hero'>
                <div className='product_hero--image'>
                    <img src={'/img/product_hero.png'} alt='talvad' />
                </div>
                <div className='product_hero--content'>
                    <p className='product_hero--content--price'>
                        {(p.price + "").split(".")[0]},
                        <span>
                            {
                                `${(p.price + "").split(".")[1]}0`.substring(0, 2)
                            }
                        </span> €
                    </p>
                    <h3>{p.title}</h3>
                    <p>
                        {p.description}
                    </p>
                </div>
            </div>
            ))
        } */}
    </Layouts>
}

export default Homepage